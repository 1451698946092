export const FACEBOOK_LINK = 'https://www.facebook.com/ColetteOfficiel';
export const INSTAGRAM_LINK = 'https://www.instagram.com/colette.club';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/coletteclub';
export const YOUTUBE_LINK = 'https://www.youtube.com/@coletteclub8279';
export const FAQ_LINK = 'https://help.colette.club/fr/';
export const JOBS_LINK = 'https://jobs.makesense.org/en/projects/colette-club-1725';
export const PRESS_ROOM_LINK = 'https://pressroom.colette.club';
export const BLOG_LINK = 'https://blog.colette.club';
export const HOUSESHARING_LINK = 'https://www.colette.club';
export const HOUSESHARING_APP_LINK = 'https://app.colette.club';
export const EXPERTS_APP_LINK = 'https://www.inscription.colette.club/lesexpertscolette';
export const APPLE_STORE_LINK = 'https://apps.apple.com/app/id6443635924';
export const GOOGLE_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=club.colette.club';

export const EMAIL = 'leclub@colette.club';
export const PHONE_INTERNATIONAL = '+33186654797';
export const PHONE = '01 86 65 47 97';
